import type {AssignmentList} from '~/types';

export default defineNuxtRouteMiddleware(async to => {
  const {isAuth} = useAuthorizationStore();

  if (!isAuth) return;

  const {fetchAssignments, setAssignmentList, assignmentList} =
    useAssignmentListStore();

  const {query, page} = to.query;

  if (!assignmentList) {
    const {data, status} = await fetchAssignments(query, page);

    if (status.value === 'success') {
      setAssignmentList(data.value! as AssignmentList);
    }
  }
});
